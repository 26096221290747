import React from "react"
import NavbarLinks from "./NavbarLinks"
import styled from 'styled-components'
import Headwave from '../header/headwave.svg';
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Navigation = styled.nav`
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--neutral-100);
    z-index: 17;
    transition: 0.4s;

    @media only screen and (min-width: 900px) {
        height: ${props => props.scroll ? "55px" : "120px"};
        background-image: ${props => props.scroll ? "none" : `url(${Headwave})`};
        background-color: ${props => props.scroll ? "var(--neutral-100)" : "var(--neutral-300)"};
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: ${props => props.scroll ? "var(--shadow-a)" : "none"};
    }
`

const LogoWrap = styled.div`
  display: block;
  width: 60%;
  max-width: ${props => props.scroll ? "200px" : "300px"};
  min-width: 200px;
  margin: 0 30px;
  transition: 0.4s;
`

const Toggle = styled.div`
    display: flex;
    height: 100%;
    cursor: pointer;
    padding: 0 5vw;

    @media only screen and (min-width: 900px) {
        display: none;
    }
`

const Menu = styled.div`
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;

    @media only screen and (max-width: 900px) {
        flex-direction: column;
        position: fixed;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 2vh;
        padding-left: 7vw;
        background-color: var(--neutral-100);
        transition: all 0.3s ease-in;
        top: 80px;
        right: ${props => (props.open ? "-100%" : "0")};
    }
`

const Hamburger = styled.div`
    background-color: var(--accent-orange-500);
    width: 25px;
    height: 2px;
    transition: all .3s linear;
    align-self: center;
    position: relative;
    transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

    ::before,
    ::after {
        width: 25px;
        height: 2px;
        background-color: var(--accent-orange-500);
        content: "";
        position: absolute;
        transition: all 0.3s linear;
    }

    ::before {
        transform: ${props =>
        props.open ? "rotate(-90deg) translate(-8px, 0px)" : "rotate(0deg)"};
        top: -8px;
    }

    ::after {
        opacity: ${props => (props.open ? "0" : "1")};
        transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
        top: 8px;
    }
`

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navbarOpen: false,
            scroll: false,
        }
        this.toggleNav = this.toggleNav.bind(this);
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.onscroll = () => {
                if (window.screen.width >= 900 && (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80)) {
                    this.setState({
                        scroll: true
                    })
                } else {
                    this.setState({
                        scroll: false
                    })
                  }
            }
        }
    }

    toggleNav() {
        let navbarOpen = this.state.navbarOpen;
        navbarOpen ? this.setState({navbarOpen: false}) : this.setState({navbarOpen: true});
    }

    render() {
        return (
            <Navigation scroll={this.state.scroll}>
                <LogoWrap as={Link} to="/" scroll={this.state.scroll}>
                    <StaticQuery
                        query={graphql`
                            query {
                                file(name: {eq: "CS_LOGO-sm"}, extension: {eq: "png"}) {
                                    childImageSharp {
                                        fluid(maxWidth: 300, pngQuality: 72) {
                                            ...GatsbyImageSharpFluid_noBase64
                                        }
                                    }
                                }
                            }
                        `}
                        render={data => (
                            <Img fluid={data.file.childImageSharp.fluid} alt="logo" />
                        )}
                    />
                </LogoWrap>
                <Toggle
                    navbarOpen={this.state.navbarOpen}
                    onClick={this.toggleNav}
                >
                    {this.state.navbarOpen ? <Hamburger open /> : <Hamburger />}
                </Toggle>
                {this.state.navbarOpen ? (
                    <Menu>
                        <NavbarLinks scroll={this.state.scroll} />
                    </Menu>
                ) : (
                    <Menu open>
                        <NavbarLinks scroll={this.state.scroll} />
                    </Menu>
                )}
            </Navigation>
        )
    }    
}
import React from "react"
import Navbar from "./header/Navbar"
import Footer from "./footer/footer"
import GlobalStyles from '../../styles/GlobalStyles';
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
    return (
        <div>
            <Helmet>
                <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');</style>
            </Helmet>
            <GlobalStyles />
            <Navbar />
            <main>
                {children}
            </main>
            <Footer />
        </div>
    )
}
import React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"

const MenuItem = styled(Link)`
        text-decoration: none;
        text-transform: uppercase;
        font-size: 17px;
        color: var(--accent-turquoise-400);
        padding: 10px;
        line-height: 28px;
        margin: 0 1.2vw;
        white-space: nowrap;
        @media only screen and (min-width: 900px) {
            color: var(--neutral-700);
            padding: 35px 0;
            &:hover {
                color: var(--accent-turquoise-400);
            }   
        }
    `

    const Submenu = styled.ul`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        padding-top: 15px;
        @media only screen and (min-width: 900px) {
            padding: 20px;
            display: ${props => props.open ? "flex" : "none"};
            position: absolute;
            top: ${props => props.scroll ? "55px" : "109px"};
            background-color: var(--neutral-100);
            border-top: 3px solid var(--accent-orange-500);
            box-shadow: var(--shadow-a);
        }
    `
    
    const SubmenuItem = styled(Link)`
        text-decoration: none;
        text-transform: uppercase;
        font-size: 17px;
        color: var(--accent-turquoise-400);
        padding: 10px 0;
        margin: 0 1vw;
        &:last-child {
            padding-bottom: 0;
        }
        @media only screen and (min-width: 900px) {
            color: hsla(0, 0%, 0%, .6);
            &:last-child {
                padding-bottom: 15px;
            }
            &:hover {
                color: var(--accent-turquoise-400);
            }
        }
    `

    const Chevron = styled.div`
        display: none;
        @media only screen and (min-width: 900px) {
            display: inline-block;
            ::before {
                border-style: solid;
                border-width: 0.15em 0.15em 0 0;
                content: '';
                display: inline-block;
                height: 0.45em;
                left: 0.5em;
                position: relative;
                top: 0.35em;
                transform: rotate(135deg);
                vertical-align: top;
                width: 0.45em;
            }
        }
    `

export default class NavbarLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submenuOpen: false
        }
        this.toggleSubmenu = this.toggleSubmenu.bind(this);
    }

    toggleSubmenu() {
        let submenuOpen = this.state.submenuOpen;
        submenuOpen ? this.setState({submenuOpen: false}) : this.setState({submenuOpen: true});
    }

    render() {
        return (
            <>
                <MenuItem to="/" activeStyle={{ color: "var(--accent-orange-500)"}}>Home</MenuItem>
                <MenuItem to="/news" activeStyle={{ color: "var(--accent-orange-500)"}}>News</MenuItem>
                <MenuItem to="/technology" activeStyle={{ color: "var(--accent-orange-500)"}} submenuOpen={this.state.submenuOpen} onMouseOver={() => this.setState({submenuOpen: true})} onMouseOut={() => this.setState({submenuOpen: false})}>Technology<Chevron />
                    {this.state.submenuOpen ? (
                        <Submenu open scroll={this.props.scroll}>
                            <SubmenuItem to="/technology" activeStyle={{ color: "var(--accent-orange-500)"}}>Core Technology</SubmenuItem>
                            <SubmenuItem to="/technology/collection" activeStyle={{ color: "var(--accent-orange-500)"}}>Solar Energy Collection</SubmenuItem>
                            <SubmenuItem to="/technology/storage" activeStyle={{ color: "var(--accent-orange-500)"}}>Thermal Energy Storage</SubmenuItem>
                            <SubmenuItem to="/technology/recovery" activeStyle={{ color: "var(--accent-orange-500)"}}>Thermal Energy Recovery</SubmenuItem>
                        </Submenu>
                    ) : (
                        <Submenu scroll={this.props.scroll}>
                            <SubmenuItem to="/technology" activeStyle={{ color: "var(--accent-orange-500)"}}>Core Technology</SubmenuItem>
                            <SubmenuItem to="/technology/collection" activeStyle={{ color: "var(--accent-orange-500)"}}>Solar Energy Collection</SubmenuItem>
                            <SubmenuItem to="/technology/storage" activeStyle={{ color: "var(--accent-orange-500)"}}>Thermal Energy Storage</SubmenuItem>
                            <SubmenuItem to="/technology/recovery" activeStyle={{ color: "var(--accent-orange-500)"}}>Thermal Energy Recovery</SubmenuItem>
                        </Submenu>
                    )}
                </MenuItem>
                <MenuItem to="/services" activeStyle={{ color: "var(--accent-orange-500)"}}>Services</MenuItem>
                <MenuItem to="/research" activeStyle={{ color: "var(--accent-orange-500)"}}>Research</MenuItem>
                <MenuItem to="/contact" activeStyle={{ color: "var(--accent-orange-500)"}}>Contact</MenuItem>
            </>
        )
    }
}
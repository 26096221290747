import React from "react"
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"

const Foot = styled.footer`
    background-color: var(--accent-turquoise-700);
`;

const Icons = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: auto;
    padding: 10px 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
    text-decoration: none;
    background-image: none;
    color: white;
    display: inline;
    padding: 5px 5px 0 5px;
    margin: 0 5px;
    }

    @media only screen and (min-width: 980px) {
        justify-content: flex-end;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    font-size: 1.5em;
`;

export default function Footer() {
    return (
        <Foot>
            <Icons>
                <a href="https://www.facebook.com/caplinsolar" target="_blank" rel="noopener noreferrer"><Icon icon={faFacebookF} size="2x" /><span className="visually-hidden">Facebook</span></a>
                <a href="https://twitter.com/caplinsolar" target="_blank" rel="noopener noreferrer"><Icon icon={faTwitter} size="2x" /><span className="visually-hidden">Twitter</span></a>
                <a href="https://www.instagram.com/caplinsolar" target="_blank" rel="noopener noreferrer"><Icon icon={faInstagram} size="2x" /><span className="visually-hidden">Instagram</span></a>
            </Icons>
        </Foot>
    )
}
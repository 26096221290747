import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    :root {
        /* Type scale */
        --type-xxs: 16px;
        --type-xs: 18px;
        --type-s: 20px;
        --type-m: 22px;
        --type-l: 24px;
        --type-xl: 26px;
        --type-xxl: 30px;
        --type-xxxl: calc(30px + 1vw);

        /* Colours */
        --neutral-100: hsl(0, 0%, 100%); /* White */
        --neutral-200: hsl(0, 0%, 97%); /* Background colour */
        --neutral-300: hsl(180, 26%, 93%); /* Hero / title background colour */
        --neutral-400: hsl(0, 0%, 80%); /* Input border colour */
        --neutral-500: hsl(0, 0%, 50%); /* Subtitle colour */
        --neutral-600: hsl(0, 0%, 40%); /* Article excerpt colour */
        --neutral-700: hsl(0, 0%, 35%); /* Menu item colour */
        --neutral-800: hsl(0, 0%, 20%); /* Article title colour */
        --neutral-900: hsl(0, 0%, 7%); /* Body font colour */
        --neutral-translucent: hsla(0, 0%, 100%, 0.7); /* Intro container background */

        --accent-orange-300: hsl(15, 87%, 57%); /* Button colour */
        --accent-orange-400: hsl(19, 90%, 55%); /* Heading underline colour */
        --accent-orange-500: hsl(28, 96%, 48%); /* Menu active item colour */
        --accent-orange-600: hsl(22, 96%, 48%); /* Latest news background colour */

        --accent-turquoise-400: hsl(184, 98%, 34%); /* Menu item hover colour */
        --accent-turquoise-500: hsl(182, 94%, 31%); /* Heading colour */
        --accent-turquoise-600: hsl(187, 92%, 30%); 
        --accent-turquoise-700: hsl(187, 92%, 29%); /* Footer colour */

        --accent-green-400: hsl(71, 57%, 52%);
        --accent-green-500: hsl(73, 57%, 48%); /* Intro "green" */

        /* Shadows */
        --shadow-a: 2px 2px 15px hsla(0,0%,0%,.1); /* Nav bar */
        --shadow-b: 0 2px 8px 0 hsla(0,0%,30%,.2); /* Sub nav */

        /* Spacing */
        --margin: 1em;


        /* @media only screen and (min-width: 790px) {
            --margin: 2rem;
        } */

        /* Use like:
        margin: var(--margin); */
    }

    * {
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: var(--type-xs);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h1, h2, h3 {
        font-size: var(--type-xl);
        font-weight: 600;
        text-transform: uppercase;
    }

    h2 {
        font-size: var(--type-l);
    }

    h3 {
        font-size: var(--type-m);
    }

    main {
        background-color: var(--neutral-300);
    }

    section {
        padding: 2.5em 0;
    }

    img {
        margin-bottom: 0;
    }

    input[type="text"], input[type="email"], select, textarea {
        font-size: var(--type-xs);
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border: 1px solid var(--neutral-400);
        border-radius: 3px;
        width: 100%;
        margin: 10px 0;
        padding: 15px;
    }

    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
        color: var(--neutral-500);
    }

    input[type="text"], input[type="email"] {
        background-image: none !important;
        display: inline-block;
    }
    
    select {
        appearance: none;
        display: inline-block;
        cursor: pointer;
        color: var(--neutral-500);
    }

    option {
        color: var(--neutral-600);
    }

    textarea {
        margin: 10px 0;
        height: 200px;
        padding: 15px;
    }

    .stdWidth {
        width: 80%;
        max-width: 1080px;
        margin: 0 auto;
    }

    .underline {
        width: 23px;
        height: 23px;
        border-top: 3px solid var(--accent-orange-400);
    }

    .center {
        margin: auto;
    }

    .heightless {
        height: 3px;
        display: none;
    }

    @media only screen and (min-width: 790px) {
        .heightless {
            display: block;
        }
    }

    .visually-hidden {
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

`;

export default GlobalStyles;
